import Vue from 'vue'
import App from './App.vue'
import { Button } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Popup } from 'vant';

Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
