import Axios from 'axios'
const instance = Axios.create({
    // baseURL:'/api' //线下本地环境，可以用vue.config.js里的代理访问
    baseURL:'http://162.14.111.16:6565/' //线上生产环境，必须改成实际访问地址，因为build打包不会打包vue的代理配置

})

instance.interceptors.request.use((config)=>{
    // console.log(config,'发送请求前config信息')
    return config
},err=>{
    return Promise.reject(err)
})

instance.interceptors.response.use((res)=>{
    // console.log('接收数据')
    return res.data
},err=>{
    return Promise.reject(err)
})

export default instance