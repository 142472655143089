<template>
  <div id="app">
    <HelloWorld :audio="audio" @addOk='addOk' @delOk='delOk'/>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import {getAudios} from '@/axios/api'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data(){
    return {
      audio:[]
    }
  },
  methods:{ 
    async getAudio(){
      let res = await getAudios()
      // console.log(res,'123', '获取用户信息')
      this.audio = res.data
      // console.log(this.audio)
    },
    addOk(e){
      console.log('收到',e)
      this.audio.push(e)
    },
    delOk(e){
      this.getAudio()
    }
  },
  created(){
    this.getAudio()
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
body{
  margin:0;
  padding:0;
  border:0;
}
</style>
