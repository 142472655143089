import request from './index'

// export const getAudios = data=> request.get('/getAudios',{params:data})
const getAudios = ()=> request.get('/getAudios')
const addAudio = (params)=>request.post('/addAudio',params)
const searchAudio = (params)=>request.post('/searchAudio',params)
const updateAudio = (params)=>request.post('/updateAudio',params)
const delAudio = (params)=>request.post('/delAudio',params)
export {getAudios,addAudio,searchAudio,updateAudio,delAudio}




