<template>
  <div class="hello">
    <h1 style="color: #000">猴锅のplayList</h1>
    <div class="avatar-container">
      <div class="avatar">
        <img src="@/assets/avatar.png" alt="头像" />
      </div>
    </div>
    <div v-if="audio.length > 0" class="player">
      <!-- <aplayer listmaxheight="200px" repeat="list" theme="#0e90d2" autoplay :music="audio[Math.floor(Math.random()*audio.length)]" :list="audio" :showLrc="true" preload="auto"></aplayer> -->
      <aplayer
        listMaxHeight="200px"
        repeat="list"
        theme="#0e90d2"
        autoplay
        :music="randomMusic"
        :list="audio"
        :showLrc="true"
        preload="auto"
        @error="handleError"
      ></aplayer>
    </div>
    <div class="form"></div>
    <div class="btn">
      <van-button type="primary" @click="addd">添加歌曲</van-button>
      <van-button type="info" @click="editt">修改歌曲</van-button>
      <van-button type="danger" @click="dell">删除歌曲</van-button>
    </div>
    <van-popup v-model="show" @closed="close" style="width: 95%">
      <van-form @submit="add" v-show="form == 'form1'">
        <van-field
          v-model="music.title"
          name="歌曲名称"
          label="歌曲名称"
          placeholder="歌曲名称"
          :rules="[{ required: true, message: '请填写歌曲名称' }]"
        />
        <van-field
          v-model="music.artist"
          name="歌手名称"
          label="歌手名称"
          placeholder="歌手名称"
          :rules="[{ required: true, message: '请填写歌手名称' }]"
        />
        <van-field
          v-model="music.src"
          name="播放地址"
          label="播放地址"
          placeholder="播放地址"
          :rules="[{ required: true, message: '请填写播放地址' }]"
        />
        <van-field
          v-model="music.pic"
          name="封面地址"
          label="封面地址"
          placeholder="封面地址"
          :rules="[{ required: true, message: '请填写封面地址' }]"
        />
        <van-field
          v-model="music.lrc"
          name="歌词内容"
          label="歌词内容"
          placeholder="歌词内容"
          :rules="[{ required: true, message: '请填写歌词内容' }]"
        />
        <van-field
          v-model="music.musicID"
          name="歌曲ID"
          label="歌曲ID"
          placeholder="歌曲ID"
          :rules="[{ required: true, message: '请填写歌曲ID' }]"
        />
        <van-field
          v-model="music.musicURL"
          name="歌曲地址"
          label="歌曲地址"
          placeholder="歌曲地址"
          :rules="[{ required: true, message: '请填写歌曲地址' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
      <van-form @submit="search" v-show="form == 'form2'">
        <van-field
          v-model="music.title"
          name="歌曲名称"
          label="歌曲名称"
          placeholder="歌曲名称"
          :rules="[{ required: true, message: '请填写歌曲名称' }]"
        />
        <van-field
          v-model="music.artist"
          name="歌手名称"
          label="歌手名称"
          placeholder="歌手名称"
          :rules="[{ required: true, message: '请填写歌手名称' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
      <van-form @submit="del" v-show="form == 'form3'">
        <van-field
          v-model="music.title"
          name="歌曲名称"
          label="歌曲名称"
          placeholder="歌曲名称"
          :rules="[{ required: true, message: '请填写歌曲名称' }]"
        />
        <van-field
          v-model="music.artist"
          name="歌手名称"
          label="歌手名称"
          placeholder="歌手名称"
          :rules="[{ required: true, message: '请填写歌手名称' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
      <van-form @submit="showEdit" v-show="form == 'form4'">
        <van-field
          v-model="music.title"
          name="歌曲名称"
          label="歌曲名称"
          placeholder="歌曲名称"
          :rules="[{ required: true, message: '请填写歌曲名称' }]"
        />
        <van-field
          v-model="music.artist"
          name="歌手名称"
          label="歌手名称"
          placeholder="歌手名称"
          :rules="[{ required: true, message: '请填写歌手名称' }]"
        />
        <van-field
          v-model="music.src"
          name="播放地址"
          label="播放地址"
          placeholder="播放地址"
          :rules="[{ required: true, message: '请填写播放地址' }]"
        />
        <van-field
          v-model="music.pic"
          name="封面地址"
          label="封面地址"
          placeholder="封面地址"
          :rules="[{ required: true, message: '请填写封面地址' }]"
        />
        <van-field
          v-model="music.lrc"
          name="歌词内容"
          label="歌词内容"
          placeholder="歌词内容"
          :rules="[{ required: true, message: '请填写歌词内容' }]"
        />
        <van-field
          v-model="music.musicID"
          name="歌曲ID"
          label="歌曲ID"
          placeholder="歌曲ID"
          :rules="[{ required: true, message: '请填写歌曲ID' }]"
        />
        <van-field
          v-model="music.musicURL"
          name="歌曲地址"
          label="歌曲地址"
          placeholder="歌曲地址"
          :rules="[{ required: true, message: '请填写歌曲地址' }]"
        />
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import Aplayer from "vue-aplayer";
import {
  getAudios,
  addAudio,
  searchAudio,
  updateAudio,
  delAudio,
} from "@/axios/api";
import { Toast } from "vant";
export default {
  name: "player",
  components: {
    Aplayer,
  },
  data() {
    return {
      show: false,
      form: "form1",
      music: {
        title: "",
        artist: "",
        src: "",
        pic: "",
        lrc: "",
        musicID: "",
        musicURL: "",
      },
    };
  },
  props: {
    audio: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    randomMusic(){
        if (this.audio.length > 0) {
          const randomIndex = Math.floor(Math.random() * this.audio.length);
          return this.audio[randomIndex];
        }
        return null;
    },
  },
  methods: {
    async addMusic(params) {
      let res = await addAudio(params);
      // console.log(res)
      if (res.isSucceed) {
        Toast("添加成功");
        this.$emit("addOk", this.music);
      } else {
        return Toast("未添加");
      }
      this.music = [];
    },
    async searchMusic(params) {
      let res = await searchAudio(params);
      console.log(res.data);
      if (res.isSucceed && res.data.length > 0) {
        this.music = res.data[0];
        this.showEdit();
      }
    },
    async updateMusic(params) {
      let res = await updateAudio(params);
      console.log(res);
    },
    async delMusic(params) {
      let res = await delAudio(params);
      if (res.isSucceed) {
        Toast("删除成功");
        this.$emit("delOk", this.music);
      } else {
        return Toast("未添加");
      }
      this.music = [];
    },
    // 方法冲突，修改歌曲后，form表为修改表，不和增加表起冲突
    addd(e) {
      this.showPopup();
    },
    serch() {
      this.form = "form2";
      this.showPopup();
    },
    editt(e) {
      this.form = "form2";
      this.showPopup();
    },
    showEdit() {
      this.form = "form1";
      this.showPopup();
    },
    dell(e) {
      this.form = "form3";
      this.showPopup();
    },
    showPopup() {
      this.show = true;
    },
    onSubmit() {
      if (this.form === "form1") {
        this.addMusic({
          title: this.music.title,
          artist: this.music.artist,
          src: this.music.src,
          pic: this.music.pic,
          lrc: this.music.lrc,
          musicID: this.music.musicID,
          musicURL: this.music.musicURL,
        });
      }
      if (this.form === "form2") {
        this.searchMusic({
          title: this.music.title,
          artist: this.music.artist,
        });
      }
      if (this.form === "form3") {
        this.delMusic({
          title: this.music.title,
          artist: this.music.artist,
        });
      }

      this.show = false;
    },
    close() {
      this.form = "form1";
      this.music = {};
    },
    handleError() {
      console.log("error");
      this.$refs.aplayer.switchAudio(5)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 80px;
  height: 80px;
  animation: rotate 60s linear infinite; /* 添加旋转动画 */
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #fff;
  }
}
@keyframes rotate {
  to {
    transform: rotate(360deg); /* 顺时针旋转360度 */
  }
}
.player {
  margin-top: 20px;
  width: 100%;
}
.hello {
  background: url("../assets/caihong.JPG");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
  text-align: center;
}
/deep/ .aplayer {
  font-family: Arial, Helvetica, sans-serif;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  .aplayer-lrc p {
    color: #0e90d2 !important;
  }
}
.btn {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.custom-button {
  background-color: #f75d74;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.custom-button:hover {
  background-color: #4d9ef7;
}
</style>
